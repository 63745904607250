// extracted by mini-css-extract-plugin
export var supportintro = "support-module--supportintro--23NL_";
export var contactdetails = "support-module--contactdetails--cAmvj";
export var cardcontainer = "support-module--cardcontainer--3mKok";
export var star = "support-module--star--398cH";
export var supporticon = "support-module--supporticon--24Ma1";
export var supportcontainer = "support-module--supportcontainer--YOd40";
export var supportHeader = "support-module--supportHeader--1Rmjr";
export var tvicon = "support-module--tvicon--1-HCf";
export var supportcover = "support-module--supportcover--3HZoq";
export var ContactButton = "support-module--ContactButton--I2P1z";
export var supportLead = "support-module--supportLead--39Hn7";
export var cardBackground = "support-module--cardBackground--1v5Bp";
export var cardtitle = "support-module--cardtitle--14IWy";
export var cardtext = "support-module--cardtext--3Y3fI";